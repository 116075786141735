import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import './relic-stats.scss';

import { Col, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { HSRStat } from '../../../modules/hsr/common/components/hsr-stat';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const HSRDatabaseRelicStatsPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Relic Stats</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_relicstats.png"
            alt="Relic Stats"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Relic Stats</h1>
          <h2>
            List of possible stats you can roll on your Relics in Honkai: Star
            Rail.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297016"></div>
      <div className="page-content">
        <SectionHeader title="Disclaimer" />
        <p>
          If you want to{' '}
          <strong>
            learn more about Relics themselves, do check our other guide
          </strong>{' '}
          as here you will find only information about the main and sub-stats
          that you can roll on them.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Relics"
            link="/star-rail/guides/relics"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_relicinfo.png"
                alt="Relics"
              />
            }
          />
        </Row>
        <SectionHeader title="Video version" />
        <p>
          This guide exists also in video format for those who prefer it. It
          also gives you some additional tips about the preference of characters
          in regard to stats.
        </p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="w2qpfgJV0n8" />
          </Col>
        </Row>
        <SectionHeader title="Relic Stats" />
        <StaticImage
          src="../../../images/starrail/generic/guide_relicstats.webp"
          alt="Relic stats"
        />
        <h5>Information about Relic stats</h5>
        <ul>
          <li>Each Relic has one main stat, determined by the Relic's type,</li>
          <ul>
            <li>Head and Hands type always have the same main stat,</li>
            <li>The other 4 slots roll a random main when you obtain them.</li>
          </ul>
          <li>
            Each Relic can have up to 4 sub-stats, where the initial and maximum
            number of them is determined by the Relic's rarity,
          </li>
          <li>
            If the Relic has fewer than 4 sub-stats, it gains a new random
            sub-stat. If the Relic already has 4 sub-stats, a random sub-stat is
            increased by a random amount,
          </li>
          <li>
            A Relic can't have duplicate sub-stats, and its sub-stats cannot be
            the same as the main stat,
          </li>
          <li>
            The initial value of each sub-stat is randomized according to the
            Relic's rarity,
          </li>
          <li>
            On any Relic Type the sub-stats are rolled from the same pool,
          </li>
          <li>
            Both main and sub-stats pools are the same for every Relic Set.
          </li>
        </ul>
        <SectionHeader title="Main stats" />
        <p>
          Each column shows a pair of values: the main stat at +0 and at
          +9/+12/+15 (depending on the rarity of the relic). There are no random
          rolls here - you always get the same main stat value once you reach a
          certain enhancement level.
        </p>
        <p>
          Switch between the tabs to check the main stats for various Relic
          types.
        </p>
        <div className="hsr-tab-container">
          <Tabs
            defaultActiveKey="head"
            id="main-stats"
            className="hsr-tabs main-stats"
            transition={false}
          >
            <Tab
              eventKey="head"
              title={
                <div>
                  <StaticImage
                    src="../../../images/starrail/icons/relic_head.png"
                    alt="Relic Head"
                  />
                  <span>Head</span>
                </div>
              }
            >
              <Table
                striped
                bordered
                responsive="sm"
                className="hsr-stat-table"
              >
                <thead>
                  <tr>
                    <th className="first">Stat</th>
                    <th>
                      <span className="hsr-rar rar-3">3★</span>{' '}
                      <span className="name">Relic</span>
                    </th>
                    <th>
                      <span className="hsr-rar rar-4">4★</span>{' '}
                      <span className="name">Relic</span>
                    </th>
                    <th>
                      <span className="hsr-rar rar-5">5★</span>{' '}
                      <span className="name">Relic</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="first">
                      <HSRStat stat="hp" />
                    </td>
                    <td>67 - 281</td>
                    <td>90 - 468</td>
                    <td>112 - 705</td>
                  </tr>
                </tbody>
              </Table>
            </Tab>
            <Tab
              eventKey="hands"
              title={
                <div>
                  <StaticImage
                    src="../../../images/starrail/icons/relic_hands.png"
                    alt="Relic Hands"
                  />
                  <span>Hands</span>
                </div>
              }
            >
              <Table
                striped
                bordered
                responsive="sm"
                className="hsr-stat-table"
              >
                <thead>
                  <tr>
                    <th className="first">Stat</th>
                    <th>
                      <span className="hsr-rar rar-3">3★</span>{' '}
                      <span className="name">Relic</span>
                    </th>
                    <th>
                      <span className="hsr-rar rar-4">4★</span>{' '}
                      <span className="name">Relic</span>
                    </th>
                    <th>
                      <span className="hsr-rar rar-5">5★</span>{' '}
                      <span className="name">Relic</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="first">
                      <HSRStat stat="atk" />
                    </td>
                    <td>33 - 140</td>
                    <td>45 - 234</td>
                    <td>56 - 352</td>
                  </tr>
                </tbody>
              </Table>
            </Tab>
            <Tab
              eventKey="body"
              title={
                <div>
                  <StaticImage
                    src="../../../images/starrail/icons/relic_body.png"
                    alt="Relic Body"
                  />
                  <span>Body</span>
                </div>
              }
            >
              <Table
                striped
                bordered
                responsive="sm"
                className="hsr-stat-table"
              >
                <thead>
                  <tr>
                    <th className="first">Stat</th>
                    <th>
                      <span className="hsr-rar rar-3">3★</span>{' '}
                      <span className="name">Relic</span>
                    </th>
                    <th>
                      <span className="hsr-rar rar-4">4★</span>{' '}
                      <span className="name">Relic</span>
                    </th>
                    <th>
                      <span className="hsr-rar rar-5">5★</span>{' '}
                      <span className="name">Relic</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="first">
                      <HSRStat stat="hp" />%
                    </td>
                    <td>4.1% - 17.2%</td>
                    <td>5.5% - 28.7%</td>
                    <td>6.9% - 43.2%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="atk" />%
                    </td>
                    <td>4.1% - 17.2%</td>
                    <td>5.5% - 28.7%</td>
                    <td>6.9% - 43.2%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="def" />%
                    </td>
                    <td>5.1% - 21.5%</td>
                    <td>6.9% - 35.9%</td>
                    <td>8.6% - 54%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="crit_rate" />
                    </td>
                    <td>3.1% - 12.9%</td>
                    <td>4.1% - 21.5%</td>
                    <td>5.1% - 32.4%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="crit_dmg" />
                    </td>
                    <td>6.2% - 25.8%</td>
                    <td>8.2% - 43.1%</td>
                    <td>10.3% - 64.8%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="healing" />
                    </td>
                    <td>3.3% - 13.7%</td>
                    <td>4.4% - 23%</td>
                    <td>5.5% - 34.5%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="effect_hit" />
                    </td>
                    <td>4.1% - 17.2%</td>
                    <td>5.5% - 28.7%</td>
                    <td>6.9% - 43.2%</td>
                  </tr>
                </tbody>
              </Table>
            </Tab>
            <Tab
              eventKey="feet"
              title={
                <div>
                  <StaticImage
                    src="../../../images/starrail/icons/relic_feet.png"
                    alt="Relic Feet"
                  />
                  <span>Feet</span>
                </div>
              }
            >
              <Table
                striped
                bordered
                responsive="sm"
                className="hsr-stat-table"
              >
                <thead>
                  <tr>
                    <th className="first">Stat</th>
                    <th>
                      <span className="hsr-rar rar-3">3★</span>{' '}
                      <span className="name">Relic</span>
                    </th>
                    <th>
                      <span className="hsr-rar rar-4">4★</span>{' '}
                      <span className="name">Relic</span>
                    </th>
                    <th>
                      <span className="hsr-rar rar-5">5★</span>{' '}
                      <span className="name">Relic</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="first">
                      <HSRStat stat="hp" />%
                    </td>
                    <td>4.1% - 17.2%</td>
                    <td>5.5% - 28.7%</td>
                    <td>6.9% - 43.2%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="atk" />%
                    </td>
                    <td>4.1% - 17.2%</td>
                    <td>5.5% - 28.7%</td>
                    <td>6.9% - 43.2%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="def" />%
                    </td>
                    <td>5.1% - 21.5%</td>
                    <td>6.9% - 35.9%</td>
                    <td>8.6% - 54%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="speed" />
                    </td>
                    <td>2 - 11</td>
                    <td>3 - 16</td>
                    <td>1 - 25</td>
                  </tr>
                </tbody>
              </Table>
            </Tab>
            <Tab
              eventKey="sphere"
              title={
                <div>
                  <StaticImage
                    src="../../../images/starrail/icons/relic_sphere.png"
                    alt="Relic Sphere"
                  />
                  <span>Planar Sphere</span>
                </div>
              }
            >
              <Table
                striped
                bordered
                responsive="sm"
                className="hsr-stat-table"
              >
                <thead>
                  <tr>
                    <th className="first">Stat</th>
                    <th>
                      <span className="hsr-rar rar-3">3★</span>{' '}
                      <span className="name">Relic</span>
                    </th>
                    <th>
                      <span className="hsr-rar rar-4">4★</span>{' '}
                      <span className="name">Relic</span>
                    </th>
                    <th>
                      <span className="hsr-rar rar-5">5★</span>{' '}
                      <span className="name">Relic</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="first">
                      <HSRStat stat="hp" />%
                    </td>
                    <td>4.1% - 17.2%</td>
                    <td>5.5% - 28.7%</td>
                    <td>6.9% - 43.2%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="atk" />%
                    </td>
                    <td>4.1% - 17.2%</td>
                    <td>5.5% - 28.7%</td>
                    <td>6.9% - 43.2%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="def" />%
                    </td>
                    <td>5.1% - 21.5%</td>
                    <td>6.9% - 35.9%</td>
                    <td>8.6% - 54%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="dmg_phys" />
                    </td>
                    <td>3.7% - 15.4%</td>
                    <td>4.9% - 25.8%</td>
                    <td>6.2% - 38.8%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="dmg_fire" />
                    </td>
                    <td>3.7% - 15.4%</td>
                    <td>4.9% - 25.8%</td>
                    <td>6.2% - 38.8%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="dmg_ice" />
                    </td>
                    <td>3.7% - 15.4%</td>
                    <td>4.9% - 25.8%</td>
                    <td>6.2% - 38.8%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="dmg_lightning" />
                    </td>
                    <td>3.7% - 15.4%</td>
                    <td>4.9% - 25.8%</td>
                    <td>6.2% - 38.8%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="dmg_wind" />
                    </td>
                    <td>3.7% - 15.4%</td>
                    <td>4.9% - 25.8%</td>
                    <td>6.2% - 38.8%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="dmg_quantum" />
                    </td>
                    <td>3.7% - 15.4%</td>
                    <td>4.9% - 25.8%</td>
                    <td>6.2% - 38.8%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="dmg_imaginary" />
                    </td>
                    <td>3.7% - 15.4%</td>
                    <td>4.9% - 25.8%</td>
                    <td>6.2% - 38.8%</td>
                  </tr>
                </tbody>
              </Table>
            </Tab>
            <Tab
              eventKey="rope"
              title={
                <div>
                  <StaticImage
                    src="../../../images/starrail/icons/relic_rope.png"
                    alt="Relic Rope"
                  />
                  <span>Link Rope</span>
                </div>
              }
            >
              <Table
                striped
                bordered
                responsive="sm"
                className="hsr-stat-table"
              >
                <thead>
                  <tr>
                    <th className="first">Stat</th>
                    <th>
                      <span className="hsr-rar rar-3">3★</span>{' '}
                      <span className="name">Relic</span>
                    </th>
                    <th>
                      <span className="hsr-rar rar-4">4★</span>{' '}
                      <span className="name">Relic</span>
                    </th>
                    <th>
                      <span className="hsr-rar rar-5">5★</span>{' '}
                      <span className="name">Relic</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="first">
                      <HSRStat stat="hp" />%
                    </td>
                    <td>4.1% - 17.2%</td>
                    <td>5.5% - 28.7%</td>
                    <td>6.9% - 43.2%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="atk" />%
                    </td>
                    <td>4.1% - 17.2%</td>
                    <td>5.5% - 28.7%</td>
                    <td>6.9% - 43.2%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="def" />%
                    </td>
                    <td>5.1% - 21.5%</td>
                    <td>6.9% - 35.9%</td>
                    <td>8.6% - 54%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="break" />
                    </td>
                    <td>6.2% - 25.2%</td>
                    <td>8.2% - 43.1%</td>
                    <td>10.3% - 64.8%</td>
                  </tr>
                  <tr>
                    <td className="first">
                      <HSRStat stat="energy_regen" />
                    </td>
                    <td>1.8% - 7.7%</td>
                    <td>2.4% - 12.9%</td>
                    <td>3.1% - 19.4%</td>
                  </tr>
                </tbody>
              </Table>
            </Tab>
          </Tabs>
          <div className="fuse-ad-placeholder" data-fuse="22844297016"></div>
          <SectionHeader title="Sub-stats" />
          <p>
            Since the{' '}
            <strong>
              sub-stat pool is the same no matter the Relic Type (or set),
              there's no point in splitting them
            </strong>
            . Just remember that you can't have the same main stat and sub-stat
            on the same item, for example if you've rolled HP% on the main stat,
            you won't be able to get HP% as one of the sub-stats (but you will
            be able to get flat HP as the game treats them as separate stats).
          </p>
          <h5>Sub-stats ranges</h5>
          <p>
            This table shows the possible sub-stats values you can get{' '}
            <strong>per one Enhance</strong>. This means the base sub-stats
            values of a Relic you've just obtained will be shown here and also
            every time you Enhance a Relic,{' '}
            <strong>one of the sub-stats will increase by that amount</strong>.
            As usual, higher rarity Relic will have higher ranges for the
            sub-stats.
          </p>
          <p>
            <strong>Important! </strong> If you've got a Relic that rolled
            different values, please let us know and we will fix the data below.
          </p>
          <Table striped bordered responsive="sm" className="hsr-stat-table">
            <thead>
              <tr>
                <th className="first">Stat</th>
                <th>
                  <span className="hsr-rar rar-3">3★</span>{' '}
                  <span className="name">Relic</span>
                </th>
                <th>
                  <span className="hsr-rar rar-4">4★</span>{' '}
                  <span className="name">Relic</span>
                </th>
                <th>
                  <span className="hsr-rar rar-5">5★</span>{' '}
                  <span className="name">Relic</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="first">
                  <HSRStat stat="hp" />
                </td>
                <td>20 - 23 - 25</td>
                <td>27 - 30 - 33</td>
                <td>33 - 38 - 42</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="atk" />
                </td>
                <td>10 - 11 - 12</td>
                <td>13 - 15 - 16</td>
                <td>16 - 19 - 21</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="def" />
                </td>
                <td>10 - 11 - 12</td>
                <td>13 - 15 - 16</td>
                <td>16 - 19 - 21</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="hp" />%
                </td>
                <td>2.0% - 2.3% - 2.5%</td>
                <td>2.7% - 3.1% - 3.4%</td>
                <td>3.4% - 3.8% - 4.4%</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="atk" />%
                </td>
                <td>2.0% - 2.3% - 2.5%</td>
                <td>2.7% - 3.1% - 3.4%</td>
                <td>3.4% - 3.8% - 4.4%</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="def" />%
                </td>
                <td>2.5% - 2.9% - 3.2%</td>
                <td>3.4% - 3.8% - 4.3%</td>
                <td>4.3% - 4.8% - 5.4%</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="crit_rate" />
                </td>
                <td>1.5% - 1.7% - 1.9%</td>
                <td>2.0% - 2.3% - 2.5%</td>
                <td>2.5% - 2.9% - 3.2%</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="crit_dmg" />
                </td>
                <td>3.1% - 3.5% - 3.8%</td>
                <td>4.1% - 4.6% - 5.1%</td>
                <td>5.1% - 5.8% - 6.5%</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="effect_hit" />
                </td>
                <td>2.0% - 2.3% - 2.5%</td>
                <td>2.7% - 3.1% - 3.4%</td>
                <td>3.4% - 3.8% - 4.3%</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="effect_res" />
                </td>
                <td>2.0% - 2.3% - 2.5%</td>
                <td>2.7% - 3.1% - 3.4%</td>
                <td>3.4% - 3.8% - 4.3%</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="break" />
                </td>
                <td>3.1% - 3.5% - 3.8%</td>
                <td>4.1% - 4.6% - 5.1%</td>
                <td>5.1% - 5.8% - 6.5%</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="speed" />
                </td>
                <td>1</td>
                <td>1.6 - 1.8 - 2</td>
                <td>2 - 2.3 - 2.6</td>
              </tr>
            </tbody>
          </Table>
          <h5>Potential dream rolls</h5>
          <p>
            This table shows the roll ranges you can obtain when Enhancing an
            Relic to the maximum possible level AND being lucky to roll into the
            same sub-stat multiple times. But keep in mind the following:
          </p>
          <ul>
            <li>
              <span className="hsr-rar rar-5">5★</span> Relic can have their
              sub-stats upgraded 5 times (if it started with 4 sub-stats
              already) or 4 times (if it started with 3 sub-stats),
            </li>
            <li>
              <span className="hsr-rar rar-4">4★</span> Relic can have their
              sub-stats upgraded 3 times (if it started with 3 sub-stats) or 2
              times (if it started with 2 sub-stats),
            </li>
            <li>
              <span className="hsr-rar rar-3">3★</span> Relic can have their
              sub-stats upgraded only 1 time (if it started with 2 sub-stats).
              If a <span className="hsr-rar rar-3">3★</span> Relic started with
              just 1 sub-stat, you won't be able to upgrade it at all.
            </li>
          </ul>
          <p>
            This also means that even if you're lucky enough to roll every time
            into the same sub-stat, the rest of them will remain at the base
            level. Also keep in mind that the right value is pure fantasy,
            especially for a <span className="hsr-rar rar-5">5★</span> Relic,
            since getting one with a sub-stat already maxed and then each
            Enhance rolling into it AND getting the max possible roll is nearly
            impossible.
          </p>
          <p>
            <strong>Important! </strong> If you've got a Relic that rolled
            different values, please let us know and we will fix the data below.
            Since the game rounds the stats down, sometimes weird things happen
            with the values when Enhancing and we are still gathering
            information about the possible sub-stats rolls - currently the
            values below are extrapolated from the single roll values.
          </p>
          <Table striped bordered responsive="sm" className="hsr-stat-table">
            <thead>
              <tr>
                <th className="first">Stat</th>
                <th>
                  <span className="hsr-rar rar-3">3★</span>{' '}
                  <span className="name">Relic</span>
                </th>
                <th>
                  <span className="hsr-rar rar-4">4★</span>{' '}
                  <span className="name">Relic</span>
                </th>
                <th>
                  <span className="hsr-rar rar-5">5★</span>{' '}
                  <span className="name">Relic</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="first">
                  <HSRStat stat="hp" />
                </td>
                <td>40 - 50</td>
                <td>108 - 135</td>
                <td>198 - 252</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="atk" />
                </td>
                <td>20 - 25</td>
                <td>54 - 67</td>
                <td>101 - 126</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="def" />
                </td>
                <td>20 - 25</td>
                <td>54 - 67</td>
                <td>101 - 126</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="hp" />%
                </td>
                <td>4.1% - 5.1%</td>
                <td>11.0% - 13.8%</td>
                <td>20.7% - 25.9%</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="atk" />%
                </td>
                <td>4.1% - 5.1%</td>
                <td>11.0% - 13.8%</td>
                <td>20.7% - 25.9%</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="def" />%
                </td>
                <td>5.1% - 6.4%</td>
                <td>13.8% - 17.2%</td>
                <td>25.9% - 32.4%</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="crit_rate" />
                </td>
                <td>3.1% - 3.8%</td>
                <td>8.2% - 10.3%</td>
                <td>15.5% - 19.4%</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="crit_dmg" />
                </td>
                <td>6.2% - 7.7%</td>
                <td>16.5% - 20.7%</td>
                <td>31.0% - 38.8%</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="effect_hit" />
                </td>
                <td>4.1% - 5.1%</td>
                <td>11.0% - 13.8%</td>
                <td>20.7% - 25.9%</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="effect_res" />
                </td>
                <td>4.1% - 5.1%</td>
                <td>11.0% - 13.8%</td>
                <td>20.7% - 25.9%</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="break" />
                </td>
                <td>6.2% - 7.7%</td>
                <td>16.5% - 20.7%</td>
                <td>31.0% - 38.8%</td>
              </tr>
              <tr>
                <td className="first">
                  <HSRStat stat="speed" />
                </td>
                <td>2 - 2</td>
                <td>6 - 8</td>
                <td>12 - 15</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
    </DashboardLayout>
  );
};

export default HSRDatabaseRelicStatsPage;

export const Head: React.FC = () => (
  <Seo
    title="Relic Stats | Honkai: Star Rail | Prydwen Institute"
    description="List of possible stats you can roll on your Relics in Honkai: Star Rail."
  />
);
